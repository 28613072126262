import React, { useState, useEffect } from "react"
import Die from "./components/Die"
import { nanoid } from "nanoid"
import Confetti from "react-confetti"

export default function App() {
  const [dice, setDice] = useState(allNewDice())
  const [tenzies, setTenzies] = useState(false)
  const [rollsThisRound, setRollsThisRound] = useState(0)
  const [scoreThisRound, setScoreThisRound] = useState(99999)
  const [scoreBest, setScoreBest] = useState(99999)

  useEffect(() => {
    const allHeld = dice.every(die => die.isHeld)
    const firstValue = dice[0].value
    const allSameValue = dice.every(die => die.value === firstValue)
    if (allHeld && allSameValue) {
      setScoreThisRound(rollsThisRound)
      setTenzies(true)
    }
  }, [dice, rollsThisRound])

  useEffect(() => {
    if (scoreThisRound < scoreBest) {
      setScoreBest(scoreThisRound)
    }
  }, [scoreThisRound, scoreBest])

  function generateNewDie() {
    return {
      value: Math.ceil(Math.random() * 6),
      isHeld: false,
      id: nanoid()
    }
  }

  function allNewDice() {
    const newDice = []
    for (let i = 0; i < 10; i++) {
      newDice.push(generateNewDie())
    }
    return newDice
  }

  function rollDice() {
    if (!tenzies) {
      setRollsThisRound(prevrollsThisRound => prevrollsThisRound + 1)
      setDice(oldDice => oldDice.map(die => {
        return die.isHeld ?
          die :
          generateNewDie()
      }))
    } else {
      setTenzies(false)
      setRollsThisRound(0)
      setDice(allNewDice())
    }
  }

  function holdDice(id) {
    setDice(oldDice => oldDice.map(die => {
      return die.id === id ?
        { ...die, isHeld: !die.isHeld } :
        die
    }))
  }

  const diceElements = dice.map(die => (
    <Die
      key={die.id}
      value={die.value}
      isHeld={die.isHeld}
      holdDice={() => holdDice(die.id)}
    />
  ))

  return (
    <main>
      {tenzies && <Confetti width={830} />}
      <h1 className="title">TENZI!</h1>
      <p className="instructions">Roll until all dice are the same.
        Click each die to freeze it at its current value between rolls.</p>
      <div className="dice-container">
        {diceElements}
      </div>
      <button
        className="roll-dice"
        onClick={rollDice}
      >
        {tenzies ? "New Game" : "Roll"}
      </button>
      <h3>Rolls this round: {rollsThisRound}</h3>
      <p>Personal Best: {scoreThisRound === 99999 ? "play to find out!" : scoreBest}</p>
    </main>
  )
}